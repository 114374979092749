import React from "react";
import { Route, Routes } from "react-router-dom";
import Contact from "../screens/guest/Contact";

import Landing from "../screens/guest/Landing";
import PrivacyPolicyPl from "../screens/guest/PrivacyPolicyPl";
import PrivacyPolicyEn from "../screens/guest/PrivacyPolicyEn";
import UnderConstruction from "../screens/guest/UnderConstruction";

import GarminRedirect from "../screens/oauth/GarminRedirect";
import BadaniaRedirect from "../screens/oauth/BadaniaRedirect";

function Navigation() {
  console.log("Render Navigation");

  return (
    <Routes>
      <Route exact path="/" element={<Landing />} />
      <Route exact path="/construction" element={<UnderConstruction />} />
      <Route exact path="/contact" element={<Contact />} />
      <Route exact path="/privacy-pl" element={<PrivacyPolicyPl />} />
      <Route exact path="/privacy-en" element={<PrivacyPolicyEn />} />
      <Route exact path="/oauth/garmin/redirect" element={<GarminRedirect />} />
      <Route
        exact
        path="/integration/badania/redirect"
        element={<BadaniaRedirect />}
      />
    </Routes>
  );
}

export default Navigation;
