import React, { useEffect } from "react";
import { useSearchParams } from "react-router-dom";

import { Image, Row } from "react-bootstrap";

import CustomText from "../../components/CustomText";

function GarminRedirect() {
  console.log("Render GarminRedirect");

  const [searchParams] = useSearchParams();

  const oauth_token = searchParams.get("oauth_token");
  const oauth_verifier = searchParams.get("oauth_verifier");

  const deeplink =
    //"exp://ep7msqo-nawbar23-8081.exp.direct/--/garmin/confirmation" +
    "online.diagme://garmin/confirmation" +
    "?oauth_token=" +
    oauth_token +
    "&oauth_verifier=" +
    oauth_verifier;

  useEffect(() => window.location.replace(deeplink), [deeplink]);

  return (
    <Row className="mt-5 mb-5 d-flex text-center align-items-center justify-content-center">
      <Image
        style={{ height: 200, width: 230 }}
        src={process.env.PUBLIC_URL + "/logo192.png"}
      />
      <Row className="mt-3" />
      <CustomText size={20} bold>
        Połączono z Garminem...
      </CustomText>
    </Row>
  );
}

export default GarminRedirect;
