import React, { useEffect } from "react";
import { useSearchParams } from "react-router-dom";

import { Image, Row } from "react-bootstrap";

import CustomText from "../../components/CustomText";

function BadaniaRedirect() {
  console.log("Render BadaniaRedirect");

  const [searchParams] = useSearchParams();

  const deeplink =
    //"exp://ep7msqo-nawbar23-8081.exp.direct/--/badania/confirmation" +
    "online.diagme://badania/confirmation" +
    "?code=" +
    searchParams.get("code");

  useEffect(() => window.location.replace(deeplink), [deeplink]);

  return (
    <Row className="mt-5 mb-5 d-flex text-center align-items-center justify-content-center">
      <Image
        style={{ height: 200, width: 230 }}
        src={process.env.PUBLIC_URL + "/logo192.png"}
      />
      <Row className="mt-3" />
      <CustomText size={20} bold>
        Zlecenie wystawione...
      </CustomText>
    </Row>
  );
}

export default BadaniaRedirect;
