import React, { useState } from "react";
import { Link, useSearchParams } from "react-router-dom";

import { useMediaQuery } from "react-responsive";

import { Button, Col, Image, Row, Carousel } from "react-bootstrap";

import YouTube from "react-youtube";

import CustomText from "../../components/CustomText";
import Colors from "../../constants/Colors";

import { IoLogoInstagram } from "react-icons/io";
import { IoLogoLinkedin } from "react-icons/io";

const getTexts = (lang) => {
  if (lang === "pl") {
    return {
      language: "English",
      slogan: "Poznaj swojego prywatnego asystenta medycznego!",
      advertMain:
        "Twoje zdrowie zależy od danych, których jeszcze nie zbierasz!",
      advertAdditional: "Zacznij z DIAGME i bądź gotowy na przyszłosć.",
      labMain: "Kiedy, jak, gdzie i o co chodzi z tymi badaniami?",
      labAdditional:
        "Jak tylko lepiej się pozamy doradzę Ci jakie badania kontrolne zrobić i poprowadzę Cie przez nie za ręke!",
      wearablesMain: "Skąd zdobyć te wszystkie dane?",
      wearablesAdditional:
        "Garmin, Apple, Samsung... Te zegarki mierzą więcej niż Ci się wydaje! Podłącz je do aplikacji i dowiedz się więcej o sobie.",
      dataMain: "Chcesz lepiej zadbać o zdrowie, ale zawsze brakuje Ci czasu?",
      dataAdditional:
        "Będę stale analizwował Twoje wyniki, śledził postępy i doradzę, co zrobić, żeby czuć się lepiej!",
      suppMain: "Wiesz, że czegoś Ci brakuje, ale czego?",
      suppAdditional:
        "Tak, wiem, suplementy są wszędzie, a ja znam się na tym! Z chęcią pomogę Ci wybrać te, których potrzebujesz, a do tego nie wydać fortuny!",
      reportMain:
        "Połowa spotkania z lekarzem to omawianie wyników z ostatnich lat?",
      reportAdditional:
        "Wykorzystajcie mój raport i skupcie się na diagnozie, a nie wertowaniu stosu makulatury!",
      contact: "Kontakt",
      privacy: "Polityka prywatności",
    };
  } else if (lang === "en") {
    return {
      language: "Polski",
      slogan: "Meet your personal medical assistant!",
      advertMain: "Your health depends on data you're not collecting yet!",
      advertAdditional: "Start with DIAGME and be ready for the future.",
      labMain: "When, how, where and what are these examinations about?",
      labAdditional:
        "As soon as we get better, I will advise you on what check-ups to undergo and I will guide you through them by hand!",
      wearablesMain: "Where do you get all this data?",
      wearablesAdditional:
        "Garmin, Apple, Samsung... These watches measure more than you think! Connect them to the app and learn more about yourself.",
      dataMain:
        "Do you want to take better care of your health, but you are always short of time?",
      dataAdditional:
        "I will constantly analyze your results, track your progress and advise you on what to do to feel better!",
      suppMain: "You know you're missing something, but what?",
      suppAdditional:
        "Yes, I know, supplements are everywhere and I know it! I will be happy to help you choose the ones you need without spending a fortune!",
      reportMain:
        "Half of your meeting with your doctor is discussing your results from recent years?",
      reportAdditional:
        "Use my report and focus on diagnosis, not browsing through piles of waste paper!",
      contact: "Contact",
      privacy: "Privacy policy",
    };
  }
};

const getDiagramImageName = (lang, isMobile) => {
  if (lang === "pl" && isMobile) return "/diagrams/diagram-pl-mobile.jpg";
  if (lang === "pl" && !isMobile) return "/diagrams/diagram-pl.jpg";
  if (lang === "en" && isMobile) return "/diagrams/diagram-en-mobile.jpg";
  if (lang === "en" && !isMobile) return "/diagrams/diagram-en.jpg";
};

const getScreenShotsByLang = (lang) => {
  if (lang === "pl")
    return [
      "/screenshots/app-1-pl.jpg",
      "/screenshots/app-2-pl.jpg",
      "/screenshots/app-3-pl.jpg",
      "/screenshots/app-4-pl.jpg",
    ];
  else
    return [
      "/screenshots/app-1-en.jpg",
      "/screenshots/app-2-en.jpg",
      "/screenshots/app-3-en.jpg",
      "/screenshots/app-4-en.jpg",
    ];
};

const getStartLang = (storedLang, urlLang) => {
  var browser = window.navigator.userLanguage || window.navigator.language;
  console.log("getStartLang", storedLang, urlLang, browser);
  if (urlLang) {
    return urlLang;
  }
  if (storedLang) {
    return storedLang;
  }
  return browser.includes("pl") ? "pl" : "en";
};

function Landing() {
  console.log("Render Landing");

  const storedLang = localStorage.getItem("lang");
  const [searchParams] = useSearchParams();

  const [lang, setLang] = useState(
    getStartLang(storedLang, searchParams.get("lang"))
  );

  const isMobile = useMediaQuery({ query: `(max-width: 760px)` });

  const onLangToggle = () => {
    const newLang = lang === "pl" ? "en" : "pl";
    localStorage.setItem("lang", newLang);
    setLang(newLang);
  };

  const webOrMobile = (webSize, mobileSize) => {
    if (isMobile) return mobileSize;
    return webSize;
  };

  const texts = getTexts(lang);
  const diagramName = getDiagramImageName(lang, isMobile);

  const ImageTextSectionLeft = ({ mainText, additionalText, image }) => {
    return (
      <Row
        style={{ backgroundColor: Colors.backgroundTertiary }}
        className="ms-0 me-0 mt-5 d-flex align-items-center justify-content-center"
      >
        <Col xs={12} md={6} className="ps-0 pe-0">
          <Image src={process.env.PUBLIC_URL + image} fluid />
        </Col>
        <Col xs={12} md={6} className="pt-3 pb-5 text-center">
          <Row className="mt-4">
            <CustomText
              color={Colors.secondary}
              size={webOrMobile(36, 24)}
              bold
            >
              {mainText}
            </CustomText>
          </Row>
          <Row>
            <CustomText color={Colors.secondary} size={webOrMobile(22, 18)}>
              {additionalText}
            </CustomText>
          </Row>
        </Col>
      </Row>
    );
  };

  const ImageTextSectionRight = ({ mainText, additionalText, image }) => {
    return (
      <Row
        style={{ backgroundColor: Colors.backgroundTertiary }}
        className="ms-0 me-0 mt-5 d-flex align-items-center justify-content-center"
      >
        <Col
          xs={12}
          md={6}
          className="pt-3 pb-5 text-center order-2 order-md-1"
        >
          <Row className="mt-4">
            <CustomText
              color={Colors.secondary}
              size={webOrMobile(36, 24)}
              bold
            >
              {mainText}
            </CustomText>
          </Row>
          <Row>
            <CustomText color={Colors.secondary} size={webOrMobile(22, 18)}>
              {additionalText}
            </CustomText>
          </Row>
        </Col>
        <Col xs={12} md={6} className="ps-0 pe-0 order-1 order-md-2">
          <Image src={process.env.PUBLIC_URL + image} fluid />
        </Col>
      </Row>
    );
  };

  const carouselElementsDesktop = getScreenShotsByLang(lang).map((n) => (
    <Carousel.Item key={n}>
      <Image
        className="mt-4 mb-4"
        src={process.env.PUBLIC_URL + n}
        height={500}
      />
    </Carousel.Item>
  ));

  const carouselElementsMobile = getScreenShotsByLang(lang).map((n) => (
    <Carousel.Item key={n}>
      <Image className="mt-4 mb-4" src={process.env.PUBLIC_URL + n} fluid />
    </Carousel.Item>
  ));

  const carouselElements = isMobile
    ? carouselElementsMobile
    : carouselElementsDesktop;

  return (
    <>
      {/* ==================================== LANG ==================================== */}
      <Row className="ms-0 me-0 mt-1 me-1">
        <Col></Col>
        <Col xs="auto">
          <Link
            style={{ textDecoration: "none" }}
            onClick={() => onLangToggle()}
          >
            <CustomText
              color={Colors.quaternary}
              size={webOrMobile(16, 14)}
              bold
            >
              {texts.language}
            </CustomText>
          </Link>
        </Col>
      </Row>
      {/* ==================================== MAIN ==================================== */}
      <Row className="ms-0 me-0 mt-1 justify-content-center">
        <Col>
          <Row className="d-flex align-items-center justify-content-center">
            <Col
              xs={6}
              md={4}
              className="d-flex align-items-center justify-content-center"
            >
              <Image
                src={process.env.PUBLIC_URL + "/logo192.png"}
                width={300}
                fluid
              />
            </Col>
            <Col xs={12} md={6} className="d-flex align-items-center">
              <Col>
                <Row className="text-center">
                  <CustomText
                    color={Colors.secondary}
                    size={webOrMobile(46, 38)}
                    bold
                  >
                    {texts.slogan}
                  </CustomText>
                </Row>
                <Row>
                  <Col className="d-flex mt-2 align-items-center justify-content-center">
                    <Link
                      to="https://apps.apple.com/us/app/diagme/id6469189916?platform=iphone"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Button className="me-2" variant="primary" size="lg">
                        <CustomText
                          color={Colors.backgroundPrimary}
                          size={webOrMobile(20, 18)}
                          bold
                        >
                          iOS
                        </CustomText>
                      </Button>
                    </Link>
                    <Link
                      to="https://play.google.com/store/apps/details?id=online.diagme"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Button className="me-2" variant="primary" size="lg">
                        <CustomText
                          color={Colors.backgroundPrimary}
                          size={webOrMobile(20, 18)}
                          bold
                        >
                          Android
                        </CustomText>
                      </Button>
                    </Link>
                  </Col>
                </Row>
              </Col>
            </Col>
          </Row>
        </Col>
      </Row>
      {/* ==================================== ADVERT ==================================== */}
      <Row
        style={{ backgroundColor: Colors.backgroundSecondary }}
        className="ms-0 me-0 mt-5 ps-2 pe-2 pb-2 text-center d-flex justify-content-center"
      >
        <Row className="mt-5">
          <CustomText
            color={Colors.backgroundPrimary}
            size={webOrMobile(40, 32)}
            bold
          >
            {texts.advertMain}
          </CustomText>
        </Row>
        <Row className="mb-5">
          <CustomText
            color={Colors.backgroundPrimary}
            size={webOrMobile(26, 18)}
          >
            {texts.advertAdditional}
          </CustomText>
        </Row>
      </Row>
      {/* ==================================== DIAGRAM ==================================== */}
      <Row
        style={{ display: "flex", width: "100%", overflow: "auto" }}
        className="ms-0 me-0 mt-5 ps-0 pe-0 text-center d-flex justify-content-center"
      >
        <Image src={process.env.PUBLIC_URL + diagramName} fluid />
      </Row>
      {/* ==================================== YOUTUBE ==================================== */}
      <Row className="ms-0 me-0 mt-3 ps-0 pe-0 text-center d-flex justify-content-center">
        <YouTube
          videoId="U4REZkjndUg"
          opts={{ height: "390", width: "100%" }}
        />
      </Row>
      {/* ==================================== LAB ==================================== */}
      <ImageTextSectionLeft
        mainText={texts.labMain}
        additionalText={texts.labAdditional}
        image={"/images/examinations.jpg"}
      />
      {/* =================================== WEAR ==================================== */}
      <ImageTextSectionRight
        mainText={texts.wearablesMain}
        additionalText={texts.wearablesAdditional}
        image={"/images/wearables.jpg"}
      />
      {/* ==================================== DATA ==================================== */}
      <ImageTextSectionLeft
        mainText={texts.dataMain}
        additionalText={texts.dataAdditional}
        image={"/images/data.jpg"}
      />
      {/* ==================================== SUPP ==================================== */}
      <ImageTextSectionRight
        mainText={texts.suppMain}
        additionalText={texts.suppAdditional}
        image={"/images/supplements.jpg"}
      />
      {/* =================================== REPORT =================================== */}
      <ImageTextSectionLeft
        mainText={texts.reportMain}
        additionalText={texts.reportAdditional}
        image={"/images/visit.jpg"}
      />
      {/* ==================================== APP ==================================== */}
      <Row className="ms-0 me-0 mt-5 ps-0 pe-0 text-center d-flex justify-content-center">
        <Carousel variant="dark" interval={1000}>
          {carouselElements}
        </Carousel>
      </Row>
      {/* ==================================== FOOTER ==================================== */}
      <Row
        style={{ backgroundColor: Colors.primary }}
        className="ms-0 me-0 mt-5 ps-0 pe-0 pt-2 pb-2 text-center d-flex justify-content-center"
      >
        <Col xs={12} md={6} className="pt-2 pb-2 ps-4 pe-4 order-2 order-md-1">
          <Row className="d-flex justify-content-center">
            <Link style={{ textDecoration: "none" }} to="contact">
              <CustomText
                color={Colors.backgroundPrimary}
                size={webOrMobile(16, 14)}
              >
                {texts.contact}
              </CustomText>
            </Link>
          </Row>
          <Row className="d-flex justify-content-center">
            <Link style={{ textDecoration: "none" }} to={"privacy-" + lang}>
              <CustomText
                color={Colors.backgroundPrimary}
                size={webOrMobile(16, 14)}
              >
                {texts.privacy}
              </CustomText>
            </Link>
          </Row>
        </Col>
        <Col xs={12} md={6} className="pt-2 pb-2 ps-3 pe-3 order-1 order-md-2">
          <Row className="justify-content-center">
            <Col xs="auto">
              <Link
                to="https://www.instagram.com/barwalll/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <IoLogoInstagram
                  style={{ height: 45, width: 45 }}
                  color={Colors.backgroundPrimary}
                  onClick={() => console.log("instagram")}
                />
              </Link>
            </Col>
            <Col xs="auto">
              <Link
                to="https://www.linkedin.com/in/bartosz-nawrot-aab890127/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <IoLogoLinkedin
                  style={{ height: 45, width: 45 }}
                  color={Colors.backgroundPrimary}
                  onClick={() => console.log("linkedin")}
                />
              </Link>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
}

export default Landing;
